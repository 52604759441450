<div class="seoul">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-two></app-header-two>
        <app-hero-seven></app-hero-seven>
        <app-branding></app-branding>
        <app-about></app-about>
        <app-work></app-work>
        <app-features></app-features>
        <app-screenshot></app-screenshot>
        <app-pricing></app-pricing>
        <app-reviews></app-reviews>
        <app-team></app-team>
        <app-newsletter></app-newsletter>
        <app-download></app-download>
        <app-blog></app-blog>
        <app-contact></app-contact>
        <app-footer></app-footer>
    </div>
</div>