<div class="pricing-page">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-one></app-header-one>
        <app-breadcrumb-pricing></app-breadcrumb-pricing>
        <div class="bg-gray">
            <app-pricing></app-pricing>
        </div>
        <app-newsletter></app-newsletter>
        <app-footer></app-footer>
    </div>
</div>