<div class="about-page">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-one></app-header-one>
        <app-breadcrumb-about></app-breadcrumb-about>
        <app-skills></app-skills>
        <app-about-us></app-about-us>
        <app-promo-three></app-promo-three>
        <app-team></app-team>
        <app-newsletter></app-newsletter>
        <app-contact></app-contact>
        <app-map></app-map>
        <app-footer></app-footer>
    </div>
</div>